import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Grid,
  List,
  Container,
  Loader,
  Form,
  Message,
  Pagination,
  Button,
  Icon,
  Input,
  Popup,
  Modal,
  Header,
  Card,
  Checkbox,
} from 'semantic-ui-react'
import { Redirect } from 'react-router-dom'
import { toastr } from 'react-redux-toastr'
import { getDeviceInfoShadow, clearCurrentDeviceInfo } from 'ducks/device'
import {
  addKey,
  clearDeviceStatus,
  removeKey,
  downloadKeys,
  deleteAllKeys,
} from 'ducks/deviceActions'
import Dropzone from 'react-dropzone'
import Key from 'components/listings/Key'
import Navbar from 'components/global/Navbar'
import AddCommentModal from 'components/modals/AddComment'

import {
  fetchKeys,
  updateSecuredKeysRequest,
  createKey,
  updateKeyComment,
  uploadButch,
} from 'ducks/keys'

const KEYS_PER_PAGE = 10

class KeysPage extends Component {
  state = {
    newKey: '',
    addKeyCommentModal: false,
  }

  componentDidMount() {
    const { match } = this.props
    this.props.getDeviceInfoShadow(match.params.id)
    this.props.dispatch(fetchKeys(match.params.id))
  }

  componentWillUnmount() {
    this.props.dispatch(clearCurrentDeviceInfo())
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.success) {
      nextProps.getDeviceInfoShadow(nextProps.match.params.id)
      toastr.success('Успех!', nextProps.success)
      setTimeout(() => nextProps.clearDeviceStatus(), 1000)
      return { newKey: '', description: '', acceptedFile: null }
    }

    if (nextProps.error) {
      toastr.error('Что-то пошло не так...', nextProps.error)
      setTimeout(() => nextProps.clearDeviceStatus(), 1000)
    }
    return prevState
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.keys !== prevProps.keys) {
      this.setState({
        currentKeys: this.props.keys,
      })
    }
    if (this.props.securedKeysData !== prevProps.securedKeysData) {
      this.setState({
        aes_key: this.props.securedKeysData?.aes_key,
        sector_number: this.props.securedKeysData?.sector_number,
        block_number: this.props.securedKeysData?.block_number,
      })
    }

    if (
      this.props.securedKeysMode !== prevProps.securedKeysMode &&
      !this.state.secured_keys_mode
    ) {
      this.setState({
        secured_keys_mode: this.props.securedKeysMode,
      })
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  onAdd = (e) => {
    const { match } = this.props
    console.log(this.state.acceptedFile)
    if (this.state.acceptedFile) {
      console.log('')
      this.props.dispatch(
        uploadButch(match.params.id, this.state.acceptedFile[0])
      )
    } else {
      this.props.dispatch(
        createKey(match.params.id, {
          key_data: this.state.newKey,
          description: this.state.description,
        })
      )
    }
  }

  downloadKeys = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props
    this.props.downloadKeys(id)
  }

  render() {
    const { match, collectingKeys } = this.props
    const { currentKeys } = this.state

    if (!match.params.id) {
      return <Redirect to="/" />
    }

    if (!collectingKeys && !currentKeys) {
      return (
        <div style={{ minHeight: '100vw' }}>
          <Loader active />
        </div>
      )
    }

    return (
      <div>
        <Navbar />
        <Container style={{ minHeight: '100vh', marginTop: '4.625rem' }}>
          <Grid>
            <Grid.Row>
              {!collectingKeys && (
                <Button
                  content="Скачать .csv с ключами"
                  icon="cloud download"
                  labelPosition="right"
                  onClick={this.downloadKeys}
                  circular
                />
              )}
              <Button
                content="Удалить все ключи"
                icon="trash"
                color="red"
                labelPosition="right"
                onClick={() => {
                  this.setState({ confirmDeleteAll: true })
                }}
                circular
              />
            </Grid.Row>
            {collectingKeys ? (
              <Grid.Row centered>
                <Message negative>
                  <Message.Header>
                    В данный момент включен автосбор. Для добавления и удаления
                    ключей выключите Автосбор
                  </Message.Header>
                </Message>
              </Grid.Row>
            ) : (
              <React.Fragment>
                <Grid.Row centered>
                  <Grid.Column>
                    <Form onSubmit={this.onAdd}>
                      <Card fluid style={{ padding: '1rem' }}>
                        <Form.Field>
                          <Input
                            name="newKey"
                            placeholder="hex"
                            value={this.state.newKey}
                            onChange={this.onChange}
                            width={16}
                            action={
                              <>
                                <Dropzone
                                  accept=".csv"
                                  multiple={false}
                                  onDrop={(accepted, rejected) => {
                                    this.setState({
                                      acceptedFile: accepted,
                                      rejectedFile: rejected,
                                      fileName: accepted[0].name,
                                    })
                                  }}>
                                  {({ getInputProps, getRootProps }) => (
                                    <Popup
                                      content=".csv файл с ключами"
                                      trigger={
                                        <Button circular {...getRootProps()}>
                                          <input {...getInputProps()} />
                                          <Icon
                                            name="file outline alternate"
                                            style={{ margin: 0 }}
                                          />
                                          {this.state.fileName}
                                        </Button>
                                      }></Popup>
                                  )}
                                </Dropzone>
                              </>
                            }
                          />
                        </Form.Field>
                        <Form.Field>
                          <Input
                            name="description"
                            placeholder="Описание"
                            value={this.state.description}
                            onChange={this.onChange}></Input>
                        </Form.Field>
                        <Form.Button type="submit" color="blue">
                          Добавить
                        </Form.Button>
                      </Card>
                    </Form>

                    <List selection size="big">
                      {currentKeys.length < 1 && (
                        <Message>
                          <Message.Header>Отсутствуют ключи</Message.Header>
                        </Message>
                      )}
                      {currentKeys.map((data, ind) => (
                        <Key
                          key={ind}
                          uuid={match.params.id}
                          onClick={this.props.removeKey}
                          openCommentModal={() =>
                            this.setState({
                              addKeyCommentModal: data,
                            })
                          }
                          {...data}
                        />
                      ))}
                    </List>
                  </Grid.Column>
                </Grid.Row>
              </React.Fragment>
            )}
          </Grid>
        </Container>
        <AddCommentModal
          open={!!this.state.addKeyCommentModal}
          onClose={() => this.setState({ addKeyCommentModal: false })}
          defaultValue={this.state.addKeyCommentModal?.description}
          handleSubmit={(values) => {
            this.props.dispatch(
              updateKeyComment(this.props.match.params.id, {
                description: values.description,
                key_data: this.state.addKeyCommentModal.key_data,
              })
            )
            this.setState({ addKeyCommentModal: false })
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ device, deviceActions, keys }) => ({
  keys: keys.list?.data, //.keys,
  totalPages: 1, // keys.list?.data.total_pages,
  success: deviceActions.success,
  error: deviceActions.error,
  collectingKeys:
    device.shadowDevice.properties &&
    device.shadowDevice.properties.base.collecting_keys,
})

export default connect(mapStateToProps, {
  getDeviceInfoShadow,
  addKey,
  clearDeviceStatus,
  removeKey,
  downloadKeys,
  updateSecuredKeysRequest,
  uploadButch,
})(KeysPage)
