import React, { useState } from 'react'
import {
  Modal,
  Header,
  Button,
  Icon,
  Table,
  Popup,
  Input,
  Form,
  Dropdown,
  Checkbox,
  Label,
} from 'semantic-ui-react'
import Flat from 'components/listings/Flat'

import * as Yup from 'yup'
import { Formik, Field, Form as FormikForm } from 'formik'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import {
  updateFlatInfo,
  clearDeviceStatus,
  lineTest,
} from 'ducks/deviceActions'
import { getDeviceInfoShadow } from 'ducks/device'
import { useParams } from 'react-router-dom'

const FormField = (props) => <Field as={Input} {...props} />

const MyForm = styled(Form)``

const sipOptions = [
  // { key: '1', text: 'после звонка', value: 'after_call' },
  { key: '1', text: 'да', value: 'direct' },
  { key: '2', text: 'нет', value: 'none' },
]

const schema = Yup.object().shape({
  threshold_call: Yup.number()
    .min(1.0, 'Не может быть меньше 1.00')
    .max(12.0, 'Не может быть больше 12.00'),
  threshold_door: Yup.number()
    .min(1.0, 'Не может быть меньше 1.00')
    .max(12.0, 'Не может быть больше 12.00'),
})

export default function FlatModal({ flat, open, onClose }) {
  const dispatch = useDispatch()
  const { id } = useParams()

  const handleSubmit = async (values) => {
    await dispatch(
      updateFlatInfo({
        ...flat,
        uuid: id,
        analog_settings: {
          ...flat?.analog_settings,
          ...values,
        },
      })
    )
    dispatch(getDeviceInfoShadow(id))

    onClose()
  }

  const handleReset = async () => {
    await dispatch(
      updateFlatInfo({
        ...flat,
        uuid: id,
        analog_settings: {
          ...flat?.analog_settings,
          threshold_call: 0,
          threshold_door: 0,
        },
      })
    )
    dispatch(getDeviceInfoShadow(id))
    setShowDefault(true)
  }

  const [showDefault, setShowDefault] = useState(
    !flat?.analog_settings ||
      (flat?.analog_settings?.threshold_door === 0.0 &&
        flat?.analog_settings?.threshold_call === 0.0)
  )

  return (
    <Modal
      // trigger={<Button onClick={this.handleModalOpen}>Show Modal</Button>}
      open={open}
      onClose={onClose}
      // basic
      size="small">
      <Header content={`Квартира ${flat?.num}`} />
      <Modal.Content>
        <h4>Уровни сопротивлений</h4>
        {showDefault ? (
          <Form
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}>
            Используются стандартные значения.
            <Button
              basic
              // icon="erase"
              content="Изменить"
              circular
              style={{ margin: '.625rem auto 0 0' }}
              onClick={() => setShowDefault(false)}
            />
          </Form>
        ) : (
          <Formik
            validationSchema={schema}
            initialValues={{
              threshold_door: parseFloat(
                flat?.analog_settings?.threshold_door || 9.99
              ).toFixed(2),
              threshold_call: parseFloat(
                flat?.analog_settings?.threshold_call || 9.99
              ).toFixed(2),
            }}
            onSubmit={handleSubmit}>
            {({ values, setFieldValue, errors }) => (
              <MyForm as={FormikForm}>
                <Form.Field>
                  <label>Порог открытия двери</label>
                  <FormField
                    name="threshold_door"
                    fluid
                    step="0.01"
                    type="number"
                  />
                  {errors?.['threshold_door'] && (
                    <Label basic color="red" pointing>
                      {errors?.['threshold_door']}
                    </Label>
                  )}
                </Form.Field>
                <Form.Field style={{ marginTop: '.625rem' }}>
                  <label>Порог снятия трубки</label>
                  <FormField
                    name="threshold_call"
                    fluid
                    step="0.01"
                    type="number"
                  />
                  {errors?.['threshold_call'] && (
                    <Label basic color="red" pointing>
                      {errors?.['threshold_call']}
                    </Label>
                  )}
                </Form.Field>

                <Modal.Actions
                  style={{
                    borderTop: '1px solid #d3d3d3',
                    marginTop: '1rem',
                    paddingTop: '1rem',
                  }}>
                  <Button onClick={onClose} circular>
                    Закрыть
                  </Button>

                  <Button
                    color="pink"
                    circular
                    type="submit"
                    disabled={Object.keys(errors).length > 0}>
                    Сохранить
                  </Button>

                  <Button
                    basic
                    icon="erase"
                    content="Вернуть стандартные"
                    circular
                    onClick={handleReset}
                    // type="submit"
                    style={{ marginLeft: '.625rem' }}
                  />
                </Modal.Actions>
              </MyForm>
            )}
          </Formik>
        )}
      </Modal.Content>
    </Modal>
  )
}
