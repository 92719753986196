import { put, takeLatest, call } from 'redux-saga/effects'
import Api from 'api/sputnikApi'
import { toastr } from 'react-redux-toastr'

import { getDeviceInfoShadowWorker } from './device'
import { REMOVE_KEY_SUCCESS } from './deviceActions'

export const UPDATE_SECURED_KEYS_REQUEST = 'keys/UPDATE_SECURED_KEYS_REQUEST'
export const UPDATE_SECURED_KEYS_SUCCESS = 'keys/UPDATE_SECURED_KEYS_SUCCESS'
export const UPDATE_SECURED_KEYS_FAILURE = 'keys/UPDATE_SECURED_KEYS_FAILURE'

export const UPDATE_KEY_COMMENT_REQUEST = 'keys/UPDATE_KEY_COMMENT_REQUEST'
export const UPDATE_KEY_COMMENT_SUCCESS = 'keys/UPDATE_KEY_COMMENT_SUCCESS'
export const UPDATE_KEY_COMMENT_FAILURE = 'keys/UPDATE_KEY_COMMENT_FAILURE'

export const FETCH_KEYS_REQUEST = 'keys/FETCH_KEYS_REQUEST'
export const FETCH_KEYS_SUCCESS = 'keys/FETCH_KEYS_SUCCESS'
export const FETCH_KEYS_FAILURE = 'keys/FETCH_KEYS_FAILURE'

export const CREATE_KEY_REQUEST = 'keys/CREATE_KEY_REQUEST'
export const CREATE_KEY_SUCCESS = 'keys/CREATE_KEY_SUCCESS'
export const CREATE_KEY_FAILURE = 'keys/CREATE_KEY_FAILURE'

export const UPLOAD_BUTCH_REQUEST = 'keys/UPLOAD_BUTCH_REQUEST'
export const UPLOAD_BUTCH_SUCCESS = 'keys/UPLOAD_BUTCH_SUCCESS'
export const UPLOAD_BUTCH_FAILURE = 'keys/UPLOAD_BUTCH_FAILURE'

export default function keys(state = {}, { type, payload }) {
  switch (type) {
    case FETCH_KEYS_SUCCESS:
      return {
        ...state,
        list: payload,
      }
    default:
      return state
  }
}

export const updateSecuredKeysRequest = (
  intercomUuid,
  { secured_keys_mode, ...params }
) => ({
  type: UPDATE_SECURED_KEYS_REQUEST,
  payload: {
    intercomUuid,
    params: {
      secured_keys_base_data: params,
      secured_keys_mode,
    },
  },
})

export const updateKeyComment = (intercomUuid, params) => ({
  type: UPDATE_KEY_COMMENT_REQUEST,
  payload: {
    intercomUuid,
    params,
  },
})

export const fetchKeys = (intercomUuid, params) => ({
  type: FETCH_KEYS_REQUEST,
  payload: {
    intercomUuid,
    params,
  },
})

export const createKey = (intercomUuid, params) => ({
  type: CREATE_KEY_REQUEST,
  payload: {
    intercomUuid,
    params,
  },
})

export const uploadButch = (intercomUuid, file, params) => ({
  type: UPLOAD_BUTCH_REQUEST,
  payload: {
    intercomUuid,
    file,
    params,
  },
})

function* updateSecuredKeysWorker({ type, payload }) {
  try {
    yield call(Api.updateSecuredKeys, payload)
    yield put({ type: UPDATE_SECURED_KEYS_SUCCESS })
    toastr.success('Успех')
  } catch (err) {
    yield put({ type: UPDATE_SECURED_KEYS_FAILURE })
    const { message } = err
    toastr.error('Что-то пошло не так!', message)
  }
}

function* updateKeyCommentWorker({ type, payload }) {
  try {
    yield call(Api.updateKeyComment, payload)
    yield put({ type: UPDATE_KEY_COMMENT_SUCCESS, payload })
    toastr.success('Успех')
  } catch (err) {
    yield put({ type: UPDATE_KEY_COMMENT_FAILURE })
    const { message } = err
    toastr.error('Что-то пошло не так!', message)
  }
}

function* fetchKeysWorker({ type, payload }) {
  try {
    const { data } = yield call(Api.fetchKeys, payload)
    yield put({ type: FETCH_KEYS_SUCCESS, payload: data })
  } catch (err) {
    yield put({ type: FETCH_KEYS_FAILURE })
    const { message } = err
    toastr.error('Что-то пошло не так!', message)
  }
}

function* createKeyWorker({ type, payload }) {
  try {
    const keys = yield call(Api.createKey, payload)
    yield put({ type: CREATE_KEY_SUCCESS, payload: payload })
    toastr.success('Успех')
  } catch (err) {
    yield put({ type: CREATE_KEY_FAILURE })
    const { message } = err
    toastr.error('Что-то пошло не так!', message)
  }
}

function* uploadButchWorker({ type, payload }) {
  try {
    const keys = yield call(Api.uploadKeysButch, payload)
    yield put({ type: UPLOAD_BUTCH_SUCCESS, payload })
    toastr.success('Успех')
  } catch (err) {
    yield put({ type: UPLOAD_BUTCH_FAILURE })
    const { message } = err
    toastr.error('Что-то пошло не так!', message)
  }
}

export function* keysSagas() {
  yield takeLatest(UPDATE_SECURED_KEYS_REQUEST, updateSecuredKeysWorker)
  yield takeLatest(UPDATE_KEY_COMMENT_REQUEST, updateKeyCommentWorker)
  yield takeLatest(FETCH_KEYS_REQUEST, fetchKeysWorker)
  yield takeLatest(CREATE_KEY_REQUEST, createKeyWorker)
  yield takeLatest(UPLOAD_BUTCH_REQUEST, uploadButchWorker)
  yield takeLatest(
    [
      CREATE_KEY_SUCCESS,
      REMOVE_KEY_SUCCESS,
      UPDATE_KEY_COMMENT_SUCCESS,
      UPLOAD_BUTCH_SUCCESS,
    ],
    fetchKeysWorker
  )
  // yield takeLatest(
  //   [ADD_BLE_KEY_SUCCESS, REMOVE_BLE_KEY_SUCCESS],
  //   getDeviceInfoShadowWorker
  // )
}
