import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Grid,
  List,
  Container,
  Loader,
  Form,
  Message,
  Pagination,
  Button,
  Icon,
  Input,
  Popup,
} from 'semantic-ui-react'
import { Redirect } from 'react-router-dom'
import { toastr } from 'react-redux-toastr'
import { getDeviceInfoShadow, clearCurrentDeviceInfo } from 'ducks/device'
import {
  clearDeviceStatus,
  getDeviceAccessCodes,
  addAccessCode,
  deleteAccessCode,
  updateCodeComment,
  uploadCodesButch,
} from 'ducks/deviceActions'
import Navbar from 'components/global/Navbar'
import MaskedInput from 'react-text-mask'
import AccessCode from 'components/listings/AccessCode'
import AddCommentModal from 'components/modals/AddComment'
import Dropzone from 'react-dropzone'

const maskBuilder = (v) => {
  return ['0', /\d/, /\d/, /\d/]
}

class AccessCodesPage extends Component {
  state = {
    keyValue: '',
    expTime: '',
    description: '',
  }

  componentDidMount() {
    const { match } = this.props
    this.props.getDeviceInfoShadow(match.params.id)
    this.props.getDeviceAccessCodes(match.params.id)
  }

  componentWillUnmount() {
    this.props.dispatch(clearCurrentDeviceInfo())
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.success) {
      nextProps.getDeviceInfoShadow(nextProps.match.params.id)
      toastr.success('Успех!', nextProps.success)
      setTimeout(() => nextProps.clearDeviceStatus(), 1000)
      return { newKey: '', acceptedFile: null }
    }

    if (nextProps.error) {
      toastr.error('Что-то пошло не так...', nextProps.error)
      setTimeout(() => nextProps.clearDeviceStatus(), 1000)
    }
    return prevState
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    if (this.state.acceptedFile) {
      this.props.dispatch(
        uploadCodesButch(this.props.match.params.id, this.state.acceptedFile[0])
      )
    } else {
      this.props.addAccessCode({
        deviceUuid: this.props.match.params.id,
        key: this.state.keyValue,
        expTime: this.state.expTime,
        description: this.state.description,
      })
    }
  }

  handleDeleteClick = (keyValue) => {
    this.props.deleteAccessCode({
      deviceUuid: this.props.match.params.id,
      key: keyValue,
    })
  }

  render() {
    const { match, accessCodes } = this.props

    if (!match.params.id) {
      return <Redirect to="/" />
    }

    if (!accessCodes) {
      return (
        <div style={{ minHeight: '100vw' }}>
          <Loader active />
        </div>
      )
    }

    return (
      <div>
        <Navbar />
        <Container style={{ marginTop: '4.625rem' }}>
          <Form onSubmit={this.handleSubmit}>
            <Form.Group widths="equal">
              <Form.Input
                label="Код"
                placeholder="четырех-/пятизначное число"
                children={
                  <MaskedInput
                    name="keyValue"
                    value={this.state.keyValue}
                    onChange={this.onChange}
                    mask={['0', /\d/, /\d/, /\d/]}
                    // mask={maskBuilder(this.state.keyValue)}
                    placeholder="четырехзначное число"
                  />
                }
              />

              <Form.Input
                label="Срок действия (опционально)"
                name="expTime"
                children={
                  <MaskedInput
                    name="expTime"
                    value={this.state.expTime}
                    onChange={this.onChange}
                    mask={[
                      /\d/,
                      /\d/,
                      '/',
                      /[0-1]/,
                      /[0-9]/,
                      '/',
                      '2',
                      '0',
                      /\d/,
                      /\d/,
                      ' ',
                      /\d/,
                      /\d/,
                      ':',
                      /\d/,
                      /\d/,
                    ]}
                    placeholder="01/09/2020 12:45"
                  />
                }
              />
            </Form.Group>
            <Form.Input
              name="description"
              label="Комментарий (опционально)"
              value={this.state.description}
              onChange={this.onChange}></Form.Input>

            <Form.Group>
              <Dropzone
                accept=".csv"
                multiple={false}
                onDrop={(accepted, rejected) => {
                  this.setState({
                    acceptedFile: accepted,
                    rejectedFile: rejected,
                    fileName: accepted[0].name,
                  })
                }}>
                {({ getInputProps, getRootProps }) => (
                  <Popup
                    content=".csv файл с ключами"
                    trigger={
                      <Button {...getRootProps()} type="button">
                        <input {...getInputProps()} />

                        <Icon
                          name="file outline alternate"
                          style={{ margin: 0 }}
                        />
                        {this.state.fileName || 'Файл'}
                      </Button>
                    }></Popup>
                )}
              </Dropzone>

              <Form.Button type="submit" color="olive" fluid>
                Добавить
              </Form.Button>
            </Form.Group>
          </Form>

          <List selection size="big" style={{ marginTop: '4rem' }}>
            {accessCodes.length < 1 && (
              <Message>
                <Message.Header>Отсутствуют коды доступа</Message.Header>
              </Message>
            )}
            {accessCodes.map(({ value, exp_time, uuid, description }) => (
              <AccessCode
                key={value}
                value={value}
                exp_time={exp_time}
                description={description}
                onClick={() => this.handleDeleteClick(uuid)}
                openCommentModal={() =>
                  this.setState({
                    addCommentModal: {
                      uuid,
                      description,
                    },
                  })
                }
              />
            ))}
          </List>
        </Container>
        <AddCommentModal
          open={!!this.state.addCommentModal}
          uuid={this.state.addCommentModal}
          onClose={() => this.setState({ addCommentModal: false })}
          defaultValue={this.state.addCommentModal?.description}
          handleSubmit={(values) => {
            this.props.dispatch(
              updateCodeComment(this.props.match.params.id, {
                description: values.description,
                uuid: this.state.addCommentModal.uuid,
              })
            )
            this.setState({ addCommentModal: false })
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ device, deviceActions }) => ({
  success: deviceActions.success,
  error: deviceActions.error,
  accessCodes: deviceActions.accessCodes,
})

export default connect(mapStateToProps, {
  getDeviceInfoShadow,
  clearDeviceStatus,
  getDeviceAccessCodes,
  addAccessCode,
  deleteAccessCode,
})(AccessCodesPage)
