import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { Grid, Accordion, Loader } from 'semantic-ui-react'
import { toastr } from 'react-redux-toastr'
import { Helmet } from 'react-helmet'

import { formatDeviceId } from 'helpers'

import {
  getDeviceInfo,
  getDeviceInfoShadow,
  clearCurrentDeviceInfo,
} from 'ducks/device'
import {
  openDoor,
  uploadFirmware,
  updateDeviceSounds,
  clearDeviceStatus,
  startEmergency,
  resetIntercom,
  resetDeviceCamera,
} from 'ducks/deviceActions'

import DevicePageHeader from './DevicePageHeader'
import Poll from 'components/categories/Poll'
import Social from 'components/categories/Social'
import Emergency from 'components/categories/Emergency'
import GeneralSettings from 'components/categories/GeneralSettings'
import FlatsListing from 'components/categories/FlatsListing'
import KeysListing from 'components/categories/KeysListing'
import AccessCodesListing from 'components/categories/AccessCodesListing'
import Firmware from 'components/categories/Firmware'
import LogsListing from 'components/categories/LogsListing'
import StandardSounds from 'components/categories/StandardSounds'
import SIP from 'components/categories/SIP'
import Navbar from 'components/global/Navbar'
import Network from 'components/categories/Network'
import SOS from 'components/categories/SOS'
import Stickies from '../global/Stickies'

import Cluster from 'components/categories/Cluster'

const DevicePageStyled = styled.div`
  min-height: 100vh;
`

const copyToClipboard = (content, notification) => {
  // copy to clipboard
  const el = document.createElement('textarea')
  el.value = content
  document.body && document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body && document.body.removeChild(el)
  toastr.info(notification)
}

class DevicePage extends Component {
  state = { activeIndex: -1, isOpen: false, doorDuration: 5 }

  componentDidMount() {
    const { match, history } = this.props
    this.props.getDeviceInfo(match.params.id, history)
    this.props.getDeviceInfoShadow(match.params.id)
  }

  componentWillUnmount() {
    this.props.clearCurrentDeviceInfo()
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  handleDoorDurationChange = (e, { value }) =>
    this.setState({ doorDuration: value })

  onStartEmergency = (e) => {
    this.props.startEmergency(this.props.currentDevice.device_id)
  }

  onResetIntercom = (e) => {
    this.props.resetIntercom(this.props.currentDevice.device_id)
  }

  onResetDeviceCamera = () => {
    this.props.resetDeviceCamera(this.props.currentDevice.device_id)
  }

  onOpen = (e) => {
    this.props.openDoor({
      id: this.props.currentDevice.device_id,
      duration: this.state.doorDuration,
    })
    this.setState({ isOpen: true })
    setTimeout(
      () => this.setState({ isOpen: false }),
      this.state.doorDuration * 1000
    )
  }

  updateSounds = (soundType, startTime, endTime, file) => {
    this.props.updateDeviceSounds({
      id: this.props.currentDevice.device_id,
      soundType,
      startTime,
      endTime,
      file,
    })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.success) {
      toastr.success('Успех!', nextProps.success)
      setTimeout(() => nextProps.clearDeviceStatus(), 1000)
    }
    if (nextProps.error) {
      toastr.error('Что-то пошло не так...', nextProps.error)
      setTimeout(() => nextProps.clearDeviceStatus(), 1000)
    }
    return prevState
  }

  copyStreamUrl = () => {
    const { currentDevice } = this.props
    if (!currentDevice) return

    // copy to clipboard
    copyToClipboard(
      currentDevice.video.stream.rtsp,
      'Ссылка на камеру была успешно скопирована'
    )
  }

  copyDeviceSerial = () => {
    const { currentDevice } = this.props
    if (!currentDevice) return

    // copy to clipboard
    copyToClipboard(
      currentDevice.motherboard_id,
      'Серийник был успешно скопирован'
    )
  }

  render() {
    const { match, currentDevice, shadowDevice, sounds } = this.props
    const { songOptions } = this.state

    if (!match.params.id) {
      return <Redirect to="/" />
    }

    if (
      Object.keys(currentDevice).length < 1 ||
      Object.keys(shadowDevice).length < 1
    ) {
      return (
        <DevicePageStyled>
          <div className="loading">
            <Loader active />
          </div>
        </DevicePageStyled>
      )
    }

    return (
      <DevicePageStyled>
        <Helmet>
          <title>
            {formatDeviceId(shadowDevice?.motherboard_id?.toString())} — WebAPI
          </title>
        </Helmet>
        <Navbar />

        <DevicePageHeader
          currentDevice={currentDevice}
          shadowDevice={shadowDevice}
          onOpen={this.onOpen}
          onResetIntercom={this.onResetIntercom}
          isOpen={this.state.isOpen}
          onCopyStreamClick={this.copyStreamUrl}
          onCopyDeviceSerial={this.copyDeviceSerial}
          onResetDeviceCamera={this.onResetDeviceCamera}
          doorDuration={this.state.doorDuration}
          handleDoorDurationChange={this.handleDoorDurationChange}
        />

        <Grid>
          <Grid.Row>
            <Grid.Column verticalAlign="middle">
              <Accordion fluid>
                <GeneralSettings
                  activeIndex={this.state.activeIndex}
                  handleClick={this.handleClick}
                />

                <Cluster
                  activeIndex={this.state.activeIndex}
                  handleClick={this.handleClick}></Cluster>
                <Social
                  activeIndex={this.state.activeIndex}
                  handleClick={this.handleClick}
                  data={shadowDevice.properties.social}
                  sounds={sounds}
                  songOptions={songOptions}
                  onUpdateSound={this.updateSounds}
                />
                <Poll
                  activeIndex={this.state.activeIndex}
                  handleClick={this.handleClick}
                  data={shadowDevice.properties.poll}
                  songOptions={songOptions}
                  onUpdateSound={this.updateSounds}
                />
                <Emergency
                  activeIndex={this.state.activeIndex}
                  handleClick={this.handleClick}
                  startEmergency={this.onStartEmergency}
                  id={match.params.id}
                  songOptions={songOptions}
                />
                <SOS
                  activeIndex={this.state.activeIndex}
                  handleClick={this.handleClick}
                  data={shadowDevice.properties.optional_button}
                />
                <SIP
                  activeIndex={this.state.activeIndex}
                  handleClick={this.handleClick}
                  data={shadowDevice.properties.sip_credentials}
                  device={shadowDevice}
                />
                <Firmware
                  activeIndex={this.state.activeIndex}
                  handleClick={this.handleClick}
                  data={currentDevice.properties.sip_credentials}
                  uploadFirmware={this.props.uploadFirmware}
                  id={shadowDevice.device_id}
                />
                <StandardSounds
                  activeIndex={this.state.activeIndex}
                  handleClick={this.handleClick}
                  id={currentDevice.device_id}
                  songOptions={songOptions}
                />
                <Network
                  activeIndex={this.state.activeIndex}
                  handleClick={this.handleClick}
                />
                <FlatsListing id={shadowDevice.device_id} />
                <KeysListing id={shadowDevice.device_id} />
                <AccessCodesListing
                  id={shadowDevice.device_id}></AccessCodesListing>
                <LogsListing id={shadowDevice.device_id} />
                <Stickies />
              </Accordion>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </DevicePageStyled>
    )
  }
}

const mapStateToProps = ({ device, deviceActions }) => ({
  currentDevice: device.currentDevice,
  shadowDevice: device.shadowDevice,
  fetched: device.fetched,
  sounds: device.sounds,
  success: deviceActions.success || device.success,
  error: deviceActions.error || device.error,
})

export default connect(mapStateToProps, {
  getDeviceInfo,
  getDeviceInfoShadow,
  openDoor,
  uploadFirmware,
  clearDeviceStatus,
  updateDeviceSounds,
  startEmergency,
  resetIntercom,
  clearCurrentDeviceInfo,
  resetDeviceCamera,
})(withRouter(DevicePage))
