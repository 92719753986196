import React, { Component } from 'react'
import {
  Accordion,
  Button,
  Container,
  Icon,
  Table,
  Form,
  Input,
  Checkbox,
} from 'semantic-ui-react'
import styled from 'styled-components'
import Flatpickr from 'react-flatpickr'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import wrapCategory from 'components/categories/wrapCategory'
import Dropzone from 'react-dropzone'
import { violet, lightRed } from 'theme.js'
import UploadSoundForm from '../forms/UploadSoundForm'
import { withRouter } from 'react-router-dom'
import { updateOptionalButtonConfig } from 'ducks/deviceActions'
import { connect } from 'react-redux'

const Wrapper = styled.div`
  .title {
    &:hover {
      color: white !important;
      background: ${violet};
    }

    &.active {
      color: white !important;
      background: ${violet};
    }
  }
`

class SOS extends Component {
  state = {
    sip_server: this.props.data.sip_server,
    sip_username: this.props.data.sip_username,
    sip_login: this.props.data.sip_login,
    sip_pass: this.props.data.sip_pass,
    sip_call_uri: this.props.data.sip_call_uri,
    use_main_sip_credentials: this.props.data.use_main_sip_credentials,
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value })

  onSubmit = (e) => {
    e.preventDefault()

    const modifiedPass = !/\*+/g.test(this.state.sip_pass)

    let newConfig = {
      id: this.props.match.params.id,
      sip_server: this.state.sip_server,
      sip_username: this.state.sip_username,
      sip_login: this.state.sip_login,
      sip_pass: modifiedPass ? this.state.sip_pass : null,
      sip_call_uri: this.state.sip_call_uri,
      use_main_sip_credentials: this.state.use_main_sip_credentials,
    }

    this.props.updateOptionalButtonConfig(newConfig)
  }
  render() {
    return (
      <Wrapper>
        <Accordion.Title
          active={this.props.activeIndex === 'sos'}
          index={'sos'}
          onClick={this.props.handleClick}
        >
          <Container className="category-title">
            Опциональная клавиша (Инфо/SOS/112)
            <Icon
              name={`angle ${
                this.props.activeIndex === 'sos' ? 'down' : 'right'
              }`}
            />
          </Container>
        </Accordion.Title>
        <Accordion.Content active={this.props.activeIndex === 'sos'}>
          <Container>
            <Form onSubmit={this.onSubmit}>
              <Form.Field>
                <label>Адрес звонка (SIP URI)</label>
                <Input
                  fluid
                  name="sip_call_uri"
                  value={this.state.sip_call_uri || ''}
                  onChange={this.onChange}
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  toggle
                  label="Использовать базовую учетную запись"
                  color="violet"
                  name="sip_incoming_call"
                  checked={this.state.use_main_sip_credentials}
                  onChange={(e, { checked }) =>
                    this.setState({ use_main_sip_credentials: checked })
                  }
                ></Checkbox>
              </Form.Field>

              {!this.state.use_main_sip_credentials && (
                <>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <label>Сервер</label>
                      <Input
                        fluid
                        placeholder="sip.sputnik.systems"
                        name="sip_server"
                        value={this.state.sip_server}
                        onChange={this.onChange}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>Имя пользователя</label>

                      <Input
                        fluid
                        placeholder="Имя пользователя"
                        value={this.state.sip_username}
                        name="sip_username"
                        onChange={this.onChange}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <label>Логин</label>

                      <Input
                        fluid
                        placeholder="Логин"
                        value={this.state.sip_login}
                        name="sip_login"
                        onChange={this.onChange}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>Пароль</label>

                      <Input
                        type={this.state.isVisible ? 'text' : 'password'}
                        fluid
                        placeholder={this.state.sip_pass}
                        value={this.state.sip_pass}
                        name="sip_pass"
                        onFocus={() => {
                          if (!this.state.isVisible) {
                            this.setState({ isVisible: true })
                            this.setState({ pass: '' })
                          }
                        }}
                        onChange={(e) => {
                          this.onChange(e)
                        }}
                      />
                    </Form.Field>
                  </Form.Group>
                </>
              )}

              <div style={{ display: 'flex' }}>
                <Button
                  circular
                  color="violet"
                  // onClick={this.onSubmit}
                  type="submit"
                  style={{
                    marginLeft: 'auto',
                    marginTop: '1.5rem',
                  }}
                >
                  Обновить данные
                </Button>
              </div>
            </Form>
          </Container>
        </Accordion.Content>
      </Wrapper>
    )
  }
}

export default connect(null, { updateOptionalButtonConfig })(
  wrapCategory(withRouter(SOS))
)
