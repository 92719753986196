import React from 'react'
import {
  Embed,
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Dropdown,
  Segment,
  List,
} from 'semantic-ui-react'
import styled from 'styled-components'
import { green, red } from '../../theme'
import { formatDeviceId } from 'helpers'
import { ReactComponent as SputnikIcon } from 'Sputnik.svg'

const styles = {
  highlight: {
    color: 'black',
  },
}
// ${p => (p.isOnline ? 'rgb(232, 245, 233)' : 'rgb(255, 204, 231)')} 0%,

const HeaderStyled = styled.div`
  /* min-height: 420px; */
  padding: 2rem 2rem;
  /* linear-gradient(to right, #3ec8ac 0%, #4e90a4 100%); */
  background: #fafafa;

  background: radial-gradient(
    at top,
    ${(p) => (p.isOnline ? 'hsl(125, 100%, 88%)' : 'rgb(255, 204, 231)')} 0%,
    #fff
  );

  /* background: radial-gradient(
      at bottom,
      hsl(125, 39%, 54%) 0%,
      hsl(125, 39%, 34%) 80%
    ); */
  /* background:linear-gradient(to right,hsl(125, 39%, 54%) 0%, hsl(125, 39%, 34%) 100% ); */
  transition: all 1s ease-in-out;

  height: 40%;

  font-size: 2.5rem;

  @media screen and (max-width: 768px) {
    padding: 1rem 0;
  }
`

const getShadowColor = (isOnline) => 'rgb(19, 111, 99) 0px 0px 20px 2px inset;'

const ActionsWrapper = styled.div`
  display: flex;
  /* flex-direction: column; */
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-top: auto;
  @media screen and (max-width: 990px) {
    width: 100%;
    align-items: center;
  }
`

const Shadow = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: rgb(19, 111, 99) 0px 0px 20px 2px inset;
`
const Wrapper = styled.div`
  display: grid;
  /* grid-template-columns: repeat(3, minmax(min-content, 1fr)); */
  grid-template-columns: 1fr min-content;
  grid-template-areas: 'embed  settings';
  grid-template-rows: max-content;
  grid-gap: 1rem;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'embed'
      'settings';
  }
`

const DevicePageHeader = ({
  shadowDevice,
  currentDevice,
  onOpen,
  isOpen,
  onResetIntercom,
  onCopyStreamClick,
  onResetDeviceCamera,
  doorDuration,
  handleDoorDurationChange,
  onCopyDeviceSerial,
}) => {
  return (
    <HeaderStyled isOnline={currentDevice.is_online}>
      <Wrapper>
        {/* <div style={{ gridArea: 'embed' }}> */}
        {/* <Container> */}
        <Segment
          style={{
            padding: 0,
            height: 'fit-content',
            overflow: 'hidden',
            margin: 0,
          }}>
          <Embed
            url={currentDevice.video.stream && currentDevice.video.stream.embed}
            placeholder={currentDevice.video.thumb}
            style={{
              // boxShadow: '0 0 20px rgba(0, 0, 0, 0.25)',
              // boxShadow: getShadowColor(currentDevice.is_online),
              // borderRadius: 30,
              // marginRight: '1rem',
              // boxShadow: `0 0 50px ${
              //   currentDevice.is_online ? green : red
              // }`,
              gridArea: 'embed',
            }}
            iframe={{
              allowFullScreen: true,
            }}></Embed>
        </Segment>
        {/* //   ) : (
          //     <Header sub textAlign="center">
          //       Нет видео
          //     </Header>
          //   )}
          // </div> */}

        <Segment
          style={{
            // height: '2.825rem',
            display: 'flex',
            // justifyContent: 'space-between',
            // flexWrap: 'wrap',
            flexDirection: 'column',
            // height: '100%',
            gridArea: 'settings',
            // overflowX: 'hidden',
            // overflowY: 'visible',
            margin: 0,
          }}>
          <Header
            size="large"
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: 0,
            }}>
            <SputnikIcon
              style={{
                marginRight: '.325rem',
              }}
            />
            {formatDeviceId(currentDevice.motherboard_id.toString())}
            <Icon
              name="copy outline"
              style={{
                marginLeft: '.325rem',
                cursor: 'pointer',
                fontSize: 14,
              }}
              size="mini"
              onClick={onCopyDeviceSerial}></Icon>
          </Header>
          <List size="large">
            <List.Item>
              <List.Icon name="microchip" verticalAlign="middle"></List.Icon>
              <List.Content>
                <List.Header>HW version</List.Header>
                <List.Description>
                  {currentDevice.hardware_version}
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="microchip" verticalAlign="middle"></List.Icon>
              <List.Content>
                <List.Header>SW version</List.Header>
                <List.Description>
                  {currentDevice.software_version}
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="bluetooth b" verticalAlign="middle"></List.Icon>
              <List.Content>
                <List.Header>BLE version</List.Header>
                <List.Description>
                  {currentDevice.ble_version || '-'}
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="barcode" verticalAlign="middle"></List.Icon>
              <List.Content>
                <List.Header>Serial Number: </List.Header>
                <List.Description>
                  {currentDevice.serial_number}
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="video camera" verticalAlign="middle"></List.Icon>
              <List.Content>
                <List.Header>Camera stream: </List.Header>
                <List.Description>
                  {currentDevice.video.stream.rtsp}
                  <List.Icon
                    name="copy outline"
                    style={{ marginLeft: '.325rem', cursor: 'pointer' }}
                    onClick={onCopyStreamClick}></List.Icon>{' '}
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
          <ActionsWrapper>
            <Button
              circular
              basic
              icon
              labelPosition="left"
              onClick={onResetDeviceCamera}
              color="red"
              style={{
                height: 'inherit',
                marginRight: '.325rem',
              }}>
              <Icon name="sync alternate" />
              Перезагрузить камеру
            </Button>
            <Button
              circular
              basic
              icon
              labelPosition="left"
              onClick={onResetIntercom}
              color="red"
              style={{
                marginTop: '.625rem',

                height: 'inherit',
                marginRight: '.325rem',
              }}>
              <Icon name="sync alternate" />
              Перезагрузить панель
            </Button>

            <Button.Group
              color="black"
              style={{
                marginTop: '.625rem',
                height: 'inherit',
                marginRight: 0,
                borderRadius: '10em',
                width: 205,
              }}
              icon>
              <Button
                icon
                circular
                labelPosition="left"
                onClick={onOpen}
                disabled={isOpen}
                style={{
                  borderRadius: '10em',
                  borderTopRightRadius: '0',
                  borderBottomRightRadius: '0',
                }}>
                {' '}
                {isOpen ? (
                  <React.Fragment>
                    <Icon name="lock open" />
                    Открыто
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Icon name="unlock" />
                    Открыть дверь
                  </React.Fragment>
                )}
              </Button>
              <Dropdown
                as={Button}
                // className="icon"
                // floating
                trigger={React.Fragment}
                icon="time"
                onChange={handleDoorDurationChange}
                value={doorDuration}
                style={{
                  borderRadius: '10em',
                  borderTopLeftRadius: '0',
                  borderBottomLeftRadius: '0',
                  background: 'rgba(0,0,0,0.1)',
                  color: 'black',
                  border: '1px solid black',
                }}
                options={timeOptions}
              />
            </Button.Group>

            {/* <Button
                      icon
                      labelPosition="left"
                      onClick={onOpen}
                      disabled={isOpen}
                      color={'black'}
                      style={{
                        marginTop: '.625rem',
                        height: 'inherit',
                        marginRight: 0,
                      }}
                      circular
                    >
                      {isOpen ? (
                        <React.Fragment>
                          <Icon name="lock open" />
                          Открыто
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <Icon name="unlock" />
                          Открыть дверь
                        </React.Fragment>
                      )}
                    </Button> */}
          </ActionsWrapper>
        </Segment>
      </Wrapper>
      {/* </Container> */}
    </HeaderStyled>
  )
}

const timeOptions = [
  {
    key: '1min',
    text: '1 минута',
    value: 60,
  },
  {
    key: '10min',
    text: '10 минут',
    value: 600,
  },
  {
    key: '1h',
    text: '1 час',
    value: 3600,
  },
  {
    key: '6h',
    text: '6 часов',
    value: 21600,
  },
  {
    key: '12h',
    text: '12 часов',
    value: 43200,
  },
  {
    key: '24h',
    text: '24 часа',
    value: 86400,
  },
]

export default DevicePageHeader
